.chroma code {
  color: #d4d4d4;
}

.chroma {
  background-color: #292929;
  margin: .5em 0;
}

/* LineTableTD */
.chroma .lntd {
  vertical-align: top;
  padding: 0;
  margin: 0;
  border: 0;
}

.chroma .lntable tbody {
  border: 0;
}

.chroma .lntable td:nth-child(2) {
  width: 100%;
}

.chroma .lnt,
.chroma .line {
  display: block;
  line-height: 1.3;
}

/* LineNumbersTable */
.chroma .lnt {
  color: #999;
  padding-left: .9em;
  padding-right: 1em;
  text-align: right;
}

.chroma .hl .lnt {
  display: inline;
}

/* Keyword */
.chroma .kc,
.chroma .kd,
.chroma .kn,
.chroma .kp,
.chroma .kr,
// .chroma .kt,
.chroma .k,
.chroma .si {
  color: #569cd6;
}

.chroma .na {
  color: #9cdcfe;
}

// .chroma .n,
.chroma .nb,
.chroma .bp,
// .chroma .nc,
.chroma .no,
.chroma .nd,
.chroma .ni,
.chroma .ne,
// .chroma .nf,
.chroma .fm,
.chroma .nl,
.chroma .nn {
  color: #569cd6;
}

/* NameProperty */
.chroma .py,
.chroma .nt,
.chroma .nv,
.chroma .vc,
.chroma .vg,
.chroma .vi,
.chroma .vm {
  color: #569cd6;
}

/* LiteralString */
.chroma .s,
.chroma .sa,
.chroma .sb,
.chroma .sc,
.chroma .dl,
.chroma .sd,
.chroma .s2,
.chroma .se,
.chroma .sh,
.chroma .sx,
.chroma .sr,
.chroma .s1,
.chroma .ss,
.chroma .cpf {
  color: #ce9178;
}
/* LiteralNumber */
.chroma .m,
.chroma .mb,
.chroma .mf,
.chroma .mh,
.chroma .mi,
.chroma .il,
.chroma .mo {
  color: #b5cea8;
}

/* Comment */
.chroma .c,
.chroma .ch,
.chroma .cm,
.chroma .c1,
.chroma .cs {
  color: #517043;
}

.chroma .nb,
.chroma .kt {
  color: #4ec9b0;
}

.chroma .fm,
.chroma .nf {
  color: #dcdcaa;
}


.chroma .cp {
  color: #c586c0;
}

.chroma .hl {
  display: block;
  background-color: #585858;
}

